import { normalizeBounds, stripPrice } from './helpers';

const defaultRange = {
  priceStep: 0.01,
  maximumPrice: 99999,
};

export const createPriceModel = (priceRange, allowEmpty) => {
  const {
    priceStep,
    minimumPrice,
    maximumPrice,
    defaultPrice,
  } = priceRange || defaultRange;

  const step = priceStep || defaultRange.priceStep;
  const minimum = minimumPrice && minimumPrice > step
    ? minimumPrice
    : step;
  const maximum = maximumPrice || defaultRange.maximumPrice;

  let initial = normalizeBounds(defaultPrice || minimum, minimum, maximum);
  initial = stripPrice(Math.round(initial / step) * step);

  return { step, minimum, maximum, initial, allowEmpty };
};

export const adjustForSalesAgreement = (quantityModel, isMaxQuantityEnforced, quantityRemaining) => {
  if (!isMaxQuantityEnforced || quantityRemaining === null ||  quantityRemaining >= quantityModel.maximum)
    return quantityModel;

  const newModel = {
    ...quantityModel,
    maximum: quantityRemaining,
  };

  if (newModel.value > newModel.maximum)
    newModel.value = newModel.maximum;

  return newModel;
};