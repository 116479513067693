import styles from './Product.module.scss';
import selectStyles from 'components/primitives/form/Select.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/primitives/form';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const InspectionProcessSelector = ({ productId, uomId, inspectionProcesses, onIpChange, className = '' }) => {
  debugger;
  
  const selectorId = `${productId}_InpectionProcess`,
  labelId = selectorId && selectorId + 'lb';
  const [
    chooseAnOption,
  ] =
  useSanaTexts([
     'ChooseAnOption',
  ], makeSimpleText).texts;

  const ipItems = useMemo(() => {
    const items = [{ name: chooseAnOption, value: null }];
    inspectionProcesses.forEach(option => items.push({ name: option.title, value: option.id }));
    return items;
  }, [chooseAnOption]);

  return (
    <div className={`${styles.uom} ${className} ${''}`}>
      <Select
        items={ipItems}
        className={selectStyles.small}
        onChange={onIpChange}
        id={selectorId}
        labelId={labelId}
        optionClassName={styles.option}
        shouldReset={productId}
      />
    </div>
  );
};

InspectionProcessSelector.propTypes = {
  productId: PropTypes.string.isRequired,
  uomId: PropTypes.string,
  inspectionProcesses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  })),
  onUomChange: PropTypes.func,
  className: PropTypes.string,
};

export default memo(InspectionProcessSelector);
